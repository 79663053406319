.ql-toolbar.ql-snow {
    padding: 0;
    margin-bottom: 0.5rem;
    border: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.ql-toolbar.ql-snow .ql-formats {
    width: 100%;
    margin: 0;
    flex-shrink: 1;
}

.ql-toolbar.ql-snow .ql-formats:nth-child(2) {
    width: 25%;
    margin: 0;
}

.ql-toolbar.ql-snow .ql-formats:nth-child(2) {
    display: flex;
    justify-content: flex-end;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
    width: 2rem;
    height: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ql-snow button.ql-image,
.ql-snow button.ql-video {
    border: 0;
    margin: 0;
    padding: 0;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
    height: 1rem;
}

.ql-snow.ql-toolbar button.ql-image svg, .ql-snow .ql-toolbar button.ql-image svg,
.ql-snow.ql-toolbar button.ql-video svg, .ql-snow .ql-toolbar button.ql-video svg {
    height: 100%;
}

.ql-snow.ql-toolbar button.ql-active {
    background: #5D728F;
    color: #FFF;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: #FFF;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 1rem;
    margin-bottom: 1.188rem;
}

.ql-editor {
    position: static;
    padding: 1rem;
    max-height: 6.313rem;
}

.ql-snow .ql-editor img {
    max-height: 10rem;
}

.ql-container {
    font-size: 1rem;
}

.ql-mention-list-container.ql-mention-list-container-bottom,
.ql-mention-list-container.ql-mention-list-container-top {
    background: #FFF;
    margin-left: -1px;
    margin-right: -1px;
    height: 10vh;
    top: -10vh;
    overflow: scroll;
    box-sizing: content-box;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.ql-mention-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    box-sizing: content-box;
}

.ql-mention-list {
    margin: 0;
    padding: 0;
}

.ql-mention-list li {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    height: 10vh;
    border-top: 1px solid rgba(0, 0, 0, 0.23);
}

.ql-mention-list li:first-child {
    border: 0;
}

.ql-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 0.625rem;
}

.ql-editor .mention {
    color: #5D728F;
    font-weight: 700;
}

.ql-mentions-opened > .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}